import "../styles/styles.css";

const RunningText = () => {
  return (
    <div className="marquee1">
      <div className="marquee-text1 mb-5">
        záleží nám na dobře odvedené práci odrážející se na spokojenosti klienta
        vracejících se referencí a opakujícího se businessu záleží nám na dobře
      </div>
    </div>
  );
};

export default RunningText;
